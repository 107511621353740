type Props = {};

export const Terms = ({}: Props) => {
  return (
    <div className="container py-20">
      <div className="space-y-6 md:mx-20">
        <div className="mb-14">
          <h1 className="text-4xl font-bold">
            Terms &<span className="ml-2 text-primary">Conditions</span>
          </h1>
          <div className="line h-1 w-40 bg-primary rounded-md mt-2"></div>
          <p className="text-lg text-suble mt-4">
            Effective Date: November 2024
          </p>
        </div>

        <p className="text-lg text-suble">
          Welcome to TeaReceipts. By accessing or using our website and mobile
          application ("TeaReceipts" or the "App"), you agree to comply with and
          be bound by the following Terms and Conditions. If you do not agree
          with these terms, please refrain from using TeaReceipts. TeaReceipts
          is intended for users who are 18 years of age or older.
        </p>

        <div>
          <h2 className="text-xl font-normal text-subleHard mb-4">
            1. Acceptance of Terms
          </h2>
          <p className="text-lg text-suble">
            By accessing TeaReceipts, you agree to these Terms and Conditions,
            as well as our Privacy Policy. If you do not agree with these terms,
            please discontinue use.
          </p>
        </div>

        <div>
          <h2 className="text-xl font-normal text-subleHard mb-4">
            2. Age Requirement
          </h2>
          <p className="text-lg text-suble">
            <strong>Eligibility:</strong> TeaReceipts is designed for users who
            are 18 years or older. By using this app, you confirm that you meet
            this age requirement.
          </p>
        </div>

        <div>
          <h2 className="text-xl font-normal text-subleHard mb-4">
            3. Use of the App
          </h2>
          <ol className="list-decimal ml-5 pl-4 space-y-2">
            <li className="text-lg text-suble">
              <strong>Purpose:</strong> TeaReceipts provides an anonymous
              platform for sharing information and forewarnings to benefit the
              community.
            </li>
            <li className="text-lg text-suble">
              <strong>Prohibited Uses:</strong> Users are strictly prohibited
              from using the App for any unlawful, harmful, or malicious
              purposes.
            </li>
            <li className="text-lg text-suble">
              <strong>Not a Substitute for Professional Advice:</strong> Content
              on TeaReceipts is user-generated and not a substitute for
              professional advice. Users are encouraged to verify information
              and seek professional advice as necessary.
            </li>
          </ol>
        </div>

        <div>
          <h2 className="text-xl font-normal text-subleHard mb-4">
            4. Prohibited Activities
          </h2>
          <ol className="list-decimal ml-5 pl-4 space-y-2">
            <li className="text-lg text-suble">
              <strong>Reverse Engineering:</strong> You shall not attempt to
              reverse engineer, decompile, disassemble, or derive the source
              code of TeaReceipts or any part thereof, except as expressly
              permitted by applicable law.
            </li>
            <li className="text-lg text-suble">
              <strong>Harmful Code:</strong> You shall not introduce, transmit,
              or knowingly allow the introduction of any harmful code (e.g.,
              viruses, malware, spyware) designed to disrupt, disable, harm, or
              impair TeaReceipts or any connected network, server, or system.
            </li>
            <li className="text-lg text-suble">
              <strong>Consequences:</strong> Any violation of these prohibitions
              may result in the termination of your access to TeaReceipts and
              may be subject to legal action.
            </li>
          </ol>
        </div>

        <div>
          <h2 className="text-xl font-normal text-subleHard mb-4">
            5. Anonymity
          </h2>
          <ol className="list-decimal ml-5 pl-4 space-y-2">
            <li className="text-lg text-suble">
              <strong>User Anonymity:</strong> TeaReceipts allows users to
              participate anonymously. However, we cannot guarantee absolute
              anonymity, and users are responsible for safeguarding their
              personal information.
            </li>
            <li className="text-lg text-suble">
              <strong>Responsibility:</strong> You are responsible for
              protecting your identity and ensuring the privacy of information
              shared on the App.
            </li>
          </ol>
        </div>

        <div>
          <h2 className="text-xl font-normal text-subleHard mb-4">
            6. Content Guidelines
          </h2>
          <ol className="list-decimal ml-5 pl-4 space-y-2">
            <li className="text-lg text-suble">
              <strong>Responsibility:</strong> Users are solely responsible for
              the content they post on TeaReceipts.
            </li>
            <li className="text-lg text-suble">
              <strong>Content Standards:</strong> Content must be accurate,
              respectful, and relevant to the community.
            </li>
            <li className="text-lg text-suble">
              <strong>Prohibited Content:</strong> Content that includes hate
              speech, harassment, illegal activities, or personal attacks is
              strictly prohibited.
            </li>
            <li className="text-lg text-suble">
              <strong>Supporting Graphics:</strong> All posts must include a
              supporting graphic attachment for any claims made.
            </li>
            <li className="text-lg text-suble">
              <strong>Privacy of Reported Parties:</strong> Posts should protect
              the identities of individuals or entities involved, except where
              otherwise allowed under search functions.
            </li>
          </ol>
        </div>

        <div>
          <h2 className="text-xl font-normal text-subleHard mb-4">
            7. Content Moderation
          </h2>
          <ol className="list-decimal ml-5 pl-4 space-y-2">
            <li className="text-lg text-suble">
              <strong>Rights to Modify or Remove Content:</strong> TeaReceipts
              reserves the right to moderate, edit, or remove content that
              violates these terms or otherwise harms the community.
            </li>
            <li className="text-lg text-suble">
              <strong>Liability:</strong> TeaReceipts is not responsible for any
              loss or damage resulting from the removal or alteration of
              content.
            </li>
          </ol>
        </div>

        <div>
          <h2 className="text-xl font-normal text-subleHard mb-4">
            8. Data Collection and Use
          </h2>
          <p className="text-lg text-suble">
            To enhance user experience, TeaReceipts collects data, including
            certain personal information, through app functionalities and
            third-party tools (SDKs). By using TeaReceipts, you consent to this
            data collection and sharing as outlined in our Privacy Policy.
          </p>
        </div>

        <div>
          <h2 className="text-xl font-normal text-subleHard mb-4">
            9. Limitation of Liability
          </h2>
          <p className="text-lg text-suble">
            TeaReceipts is provided on an "as-is" and "as available" basis,
            without warranties of any kind. You use TeaReceipts at your own
            risk, and we disclaim all liability for damages or data loss
            resulting from its use.
          </p>
        </div>

        <div>
          <h2 className="text-xl font-normal text-subleHard mb-4">
            10. Governing Law
          </h2>
          <p className="text-lg text-suble">
            These Terms are governed by and construed in accordance with the
            laws of Georgia, USA, without regard to its conflict of law
            provisions.
          </p>
        </div>

        <p className="text-lg text-suble mt-6">
          By using TeaReceipts, you agree to these Terms and Conditions. If you
          have questions or concerns, please contact us at{" "}
          <a href="mailto:info@teareceipts.com" className="text-primary">
            info@teareceipts.com
          </a>
          .
        </p>
      </div>
    </div>
  );
};
