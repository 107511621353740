import {
  createRoutesFromElements,
  createBrowserRouter,
  Route,
  RouterProvider,
} from "react-router-dom";
import Root from "./layout";
import {
  Home,
  Faq,
  Contact,
  SigninPage,
  Terms,
  DashboardPage,
  OtpVerification,
  Post,
  PrivacyPolicy,
} from "./pages";
import SignupPage from "./pages/signup";
import { Toaster } from "./components/ui/toaster";
import QueryProvider from "./lib/react-query-provider";
import ProtectedRoute from "./components\u0017/custom/protectedRouter";
import DashboardLayout from "./layout/teareceipts";
import Dialogs from "./components\u0017/Dialogs";

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        {/* home pages */}
        <Route path="/" element={<Home />} />
        <Route path="/" element={<Root />}>
          <Route path="faq" element={<Faq />} />
          <Route path="contact-us" element={<Contact />} />
          <Route path="terms" element={<Terms />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
        </Route>

        {/* dashboard  */}
        <Route path="/" element={<DashboardLayout />}>
          {/* protected routes */}
          <Route element={<ProtectedRoute />}>
            <Route path="/teareceipts" element={<DashboardPage />} />
          </Route>
          <Route path="/teareceipts/:id" element={<Post />} />
        </Route>

        {/* auth route  */}
        <Route path="sign-in" element={<SigninPage />} />
        <Route path="sign-up" element={<SignupPage />} />
        <Route path="otp-verification" element={<OtpVerification />} />
      </>
    )
  );

  return (
    <div className="App">
      <QueryProvider>
        <RouterProvider router={router} />
        <Dialogs />
        <Toaster />
      </QueryProvider>
    </div>
  );
}

export default App;
