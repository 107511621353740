import Heading from "../components\u0017/custom/heading";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../components/ui/accordion";

type Props = {};

export const Faq = ({}: Props) => {
  return (
    <div className="container py-20">
      <div className="">
        <Heading title="Frequently Asked Questions">
        </Heading>
      </div>
      <div>
        <Accordion
          type="single"
          collapsible
          className="w-full space-y-10 font-spartan">
          <AccordionItem value="item-1">
            <AccordionTrigger className="text-xl font-normal text-subleHard">
              What is TeaReceipts?
            </AccordionTrigger>
            <AccordionContent className="text-lg text-suble">
              TeaReceipts is a community-driven platform where individuals can
              anonymously share forewarning advice along with supporting
              attachments or documents (receipts) that serve as evidence for the
              claims made in a post. It provides visual confirmation, offering
              substantial evidence, data, or relevant information that bolsters
              the validity and credibility of assertions made for the benefit of
              the community.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-2">
            <AccordionTrigger className="text-xl font-normal text-subleHard">
              How does anonymity work on this platform?
            </AccordionTrigger>
            <AccordionContent className="text-lg text-suble">
              We prioritize user anonymity by not requiring personal
              identification for contributions. Users can share their
              experiences and warnings without revealing their identities.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-3">
            <AccordionTrigger className="text-xl font-normal text-subleHard">
              Can I trust the information shared on TeaReceipts?
            </AccordionTrigger>
            <AccordionContent className="text-lg text-suble">
              While we encourage responsible and accurate sharing, we advise
              users to verify information independently. TeaReceipts acts as a
              platform for community-contributed content and doesn't guarantee
              the accuracy of shared information.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-4">
            <AccordionTrigger className="text-xl font-normal text-subleHard">
              What kind of content can I share on TeaReceipts?
            </AccordionTrigger>
            <AccordionContent className="text-lg text-suble">
              You can share cautionary tales, safety concerns, advice, or any
              information you believe would benefit the community by providing
              foresight into potential risks or challenges accompanied by an
              attachment that supports these claims (a receipt). Explicit adult
              content images or nudity are excluded.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-5">
            <AccordionTrigger className="text-xl font-normal text-subleHard">
              Is there any moderation of content on the platform?
            </AccordionTrigger>
            <AccordionContent className="text-lg text-suble">
              Yes, TeaReceipts has content moderation in place to ensure that
              contributions align with our community guidelines. Content
              violating these guidelines will be removed.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-6">
            <AccordionTrigger className="text-xl font-normal text-subleHard">
              How can I report inappropriate content or behavior?
            </AccordionTrigger>
            <AccordionContent className="text-lg text-suble">
              If you encounter inappropriate content or behavior, please report
              it immediately using the "report a post" option available on the
              platform.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-7">
            <AccordionTrigger className="text-xl font-normal text-subleHard">
              Can I contact other users privately?
            </AccordionTrigger>
            <AccordionContent className="text-lg text-suble">
              To maintain anonymity, direct private communication (DMs) between
              users is not facilitated on the platform. Discussions between
              users are only available in the comment section. All contributions
              are made anonymously for the collective benefit of the community.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-8">
            <AccordionTrigger className="text-xl font-normal text-subleHard">
              Are there any guidelines for posting content?
            </AccordionTrigger>
            <AccordionContent className="text-lg text-suble">
              Yes, we encourage respectful, relevant, and accurate content.
              Please avoid hate speech, harassment, illegal activities, or
              personal attacks. Review our Rules and Terms and Conditions for
              more information.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-9">
            <AccordionTrigger className="text-xl font-normal text-subleHard">
              Is my personal information safe on TeaReceipts?
            </AccordionTrigger>
            <AccordionContent className="text-lg text-suble">
              We take user privacy seriously and employ robust security measures
              to protect user data and anonymity. However, users are responsible
              and required to maintain their anonymity while using the platform.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-10">
            <AccordionTrigger className="text-xl font-normal text-subleHard">
              How can I contribute to TeaReceipts?
            </AccordionTrigger>
            <AccordionContent className="text-lg text-suble">
              Simply sign up and start sharing your forewarnings, alerts, or
              advice anonymously. Your contributions are invaluable in creating
              a stronger and more informed community.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-11">
            <AccordionTrigger className="text-xl font-normal text-subleHard">
              How often is the platform updated or maintained?
            </AccordionTrigger>
            <AccordionContent className="text-lg text-suble">
              We strive to regularly update and maintain the platform to ensure
              a smooth user experience and implement necessary improvements
              based on user feedback.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-12">
            <AccordionTrigger className="text-xl font-normal text-subleHard">
              Where can I find additional help or support?
            </AccordionTrigger>
            <AccordionContent className="text-lg text-suble">
              If you have further questions or need support, email
              info@teareceipts.co to get in touch with our team.
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  );
};

export default Faq;
