type Props = {};

export const PrivacyPolicy = ({}: Props) => {
  return (
    <div className="container py-20">
      <div className="space-y-6 md:mx-20">
        <div className="mb-14">
          <h1 className="text-4xl font-bold">
            Privacy <span className="ml-2 text-primary">Policy</span>
          </h1>
          <div className="line h-1 w-40 bg-primary rounded-md mt-2"></div>
          <p className="text-lg text-suble mt-4">
            Effective Date: November 2024
          </p>
        </div>

        <p className="text-lg text-suble">
          TeaReceipts values your privacy. This Privacy Policy outlines how we
          collect, use, protect, and disclose your personal information when
          you use our mobile app and website ("TeaReceipts" or the "App"). By
          using TeaReceipts, you agree to this Privacy Policy and to our Terms
          and Conditions. Please note that TeaReceipts is intended for users
          who are at least 18 years of age. If you do not meet this age
          requirement or do not agree to this Privacy Policy, please do not use
          TeaReceipts.
        </p>

        <div>
          <h2 className="text-xl font-normal text-subleHard mb-4">
            1. Age Requirement
          </h2>
          <p className="text-lg text-suble">
            <strong>Eligibility:</strong> TeaReceipts is intended for
            individuals who are at least 18 years of age. By using our App, you
            confirm that you meet this age requirement.
          </p>
        </div>

        <div>
          <h2 className="text-xl font-normal text-subleHard mb-4">
            2. Information Collection and Use
          </h2>
          <ol className="list-decimal ml-5 pl-4 space-y-2">
            <li className="text-lg text-suble">
              <strong>Personal Information:</strong> We may collect information
              such as your email address and other identifiers when necessary
              for functionality, analytics, and ad services.
            </li>
            <li className="text-lg text-suble">
              <strong>Usage Data:</strong> To enhance user experience and ensure
              the App operates effectively, we collect certain usage data,
              including interactions within the App, app settings, and
              preferences.
            </li>
            <li className="text-lg text-suble">
              <strong>Third-Party Services:</strong> TeaReceipts may use
              third-party tools, software development kits (SDKs), and
              analytics providers to support functionalities, ad services, and
              user experience improvements. These providers are required to
              comply with privacy and security standards when handling your
              information.
            </li>
          </ol>
        </div>

        <div>
          <h2 className="text-xl font-normal text-subleHard mb-4">
            3. Purpose of Data Collection
          </h2>
          <p className="text-lg text-suble">
            We collect and use data to improve TeaReceipts, personalize your
            experience, provide app functionalities, and ensure App security. By
            using TeaReceipts, you consent to this collection and processing of
            data as outlined.
          </p>
        </div>

        <div>
          <h2 className="text-xl font-normal text-subleHard mb-4">
            4. Data Sharing and Third-Party Disclosure
          </h2>
          <ol className="list-decimal ml-5 pl-4 space-y-2">
            <li className="text-lg text-suble">
              <strong>Third-Party Providers:</strong> To provide you with core
              functionalities, we may share your data with third-party service
              providers, such as analytics and ad networks, for purposes
              including app performance optimization, personalized user
              experience, and operational support.
            </li>
            <li className="text-lg text-suble">
              <strong>Legal Requirements:</strong> We may disclose your
              information when required by law or to protect the rights, safety,
              and security of TeaReceipts, our users, or others.
            </li>
          </ol>
        </div>

        <div>
          <h2 className="text-xl font-normal text-subleHard mb-4">
            5. Anonymity and User Responsibility
          </h2>
          <p className="text-lg text-suble">
            TeaReceipts allows users to participate anonymously, but absolute
            anonymity cannot be guaranteed. Users are encouraged to safeguard
            their own identity and personal information. Posts or content
            should avoid sharing identifying information unless necessary.
          </p>
        </div>

        <div>
          <h2 className="text-xl font-normal text-subleHard mb-4">
            6. Data Security
          </h2>
          <p className="text-lg text-suble">
            We take data security seriously and use commercially reasonable
            efforts to protect your information from unauthorized access,
            disclosure, alteration, or destruction. However, no method of
            transmission or storage is entirely secure, and we cannot guarantee
            the absolute security of your information.
          </p>
        </div>

        <div>
          <h2 className="text-xl font-normal text-subleHard mb-4">
            7. Content Responsibility
          </h2>
          <p className="text-lg text-suble">
            Users are responsible for the content they post on TeaReceipts.
            Content should comply with our Content Guidelines and not include
            personal attacks, hate speech, or illegal activities. Posts must
            include supportive graphic evidence for claims while protecting the
            identities of reported parties as required.
          </p>
        </div>

        <div>
          <h2 className="text-xl font-normal text-subleHard mb-4">
            8. Changes to the Privacy Policy
          </h2>
          <p className="text-lg text-suble">
            We may update this Privacy Policy to reflect changes in our
            practices, the App's functionalities, or legal requirements. When
            updates are made, the "Effective Date" at the top will be revised.
            Continued use of TeaReceipts after updates constitutes acceptance of
            the modified Privacy Policy.
          </p>
        </div>

        <div>
          <h2 className="text-xl font-normal text-subleHard mb-4">
            9. Limitation of Liability
          </h2>
          <p className="text-lg text-suble">
            TeaReceipts is provided on an "as-is" basis, with no guarantees
            regarding app functionality, availability, or performance. Users
            accept full responsibility for their use of the App, including any
            device or data loss.
          </p>
        </div>

        <div>
          <h2 className="text-xl font-normal text-subleHard mb-4">
            10. Contact Information
          </h2>
          <p className="text-lg text-suble">
            If you have questions, concerns, or feedback regarding this Privacy
            Policy, please contact us at info@teareceipts.com.
          </p>
        </div>
      </div>
    </div>
  );
};
