import { z } from "zod";

export const signInFormSchema = z.object({
  email: z
    .string({ required_error: "Email is required." })
    .email({ message: "Invalid email address" }),
  password: z
    .string({ required_error: "Password is required." })
    .min(6, { message: "Password must be at least 6 characters" }),
});

export const signupFormSchema = z
  .object({
    // name: z.string().min(2, { message: "Name must be at least 2 characters" }),
    email: z.string().email({ message: "Must be a valid email" }),
    password: z
      .string()
      .min(8, { message: "Password must be at least 8 characters" }),
    confirmPassword: z
      .string()
      .min(8, { message: "Password must be at least 8 characters" }),
    age: z
      .number()
      .min(18, "You must be at least 18 years old")
      .max(100, "Age cannot be more than 100"),
    terms: z.literal(true, {
      errorMap: () => ({ message: "You must accept the terms and conditions" }),
    }),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ["confirmPassword"],
  });

export const commentSchema = z.object({
  comment: z.string({ required_error: "comment is required." }),
});
